export function data() {
  return {
    formData: {
      ownerID: 0,
      ownerInsuranceID: 0,
      ownerInsuranceCoverageTypeID: 0,
      coverageAmount: 0,
      insuranceCompanyName: '',
      policyNumber: '',
      expirationDate: null
    },
    activeStep: 0,
    isDebug: true,
    newId: null,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    showWeekNumber: false,
    ownerInsuranceCoverageTypeList: [],
    ownerDropDownList: [],
    buttonText: 'Add',
    styles: {
      datePickerContainer: { display: 'flex', alignItems: 'end' },
      datePicker: { margin: 0, marginRight: '12px' }
    }
  }
}
