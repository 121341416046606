/*
  import { insuranceStore } from '@/services/Units/Tabs/Insurance/InsuranceModal/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import _get from 'lodash/get'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getOwnerInsuranceCoverageTypeList({}) {
    console.log('in getOwnerInsuranceCoverageTypeList...')
    try {
      const result = await kms.get('/OwnerInsuranceCoverageType/OwnerInsuranceCoverageType/List')

      const dropDown = result.results
        .map(a => ({
          value: _get(a, ['ownerInsuranceCoverageTypeID'], 0),

          label: _get(a, ['name'], ''),
          codename: _get(a, ['codeHandle'], '')
        }))
        .filter(f => f.label.length > 0 && f.value > 0)

      dropDown.unshift({
        value: '',
        label: '<-Select Coverage Type->',
        codename: ''
      })

      if (isDebug == true)
        console.debug('getOwnerInsuranceCoverageTypeList log=' + JSON.stringify(result))

      return {
        list: dropDown
      }
    } catch (exception) {
      notifyProblem(`The owner insurance coverage type list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  // Update Insurance
  async updateInsurance({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/OwnerInsurance/OwnerInsurance`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.ownerInsuranceID > 0) {
        notifyMessage(`Successfully updated the selected insurance.`)
        done({ details: results })
      } else {
        notifyError('There was a problem updating the selected insurance.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for this insurance.`)
      console.error(exception)
    }
  },

  async addInsurance(
    { dispatch },
    {
      ownerID,
      ownerInsuranceCoverageTypeID,
      coverageAmount,
      insuranceCompanyName,
      policyNumber,
      expirationDate,
      done
    }
  ) {
    if (isDebug == true) console.debug('...in addInsurance')
    try {
      const results = await kms.post(`/OwnerInsurance/OwnerInsurance`, {
        ownerID,
        ownerInsuranceCoverageTypeID,
        coverageAmount,
        insuranceCompanyName,
        policyNumber,
        expirationDate
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.ownerInsuranceID > 0) {
        notifyMessage(`Successfully added the insurance.`)
        done({ details: results })
      } else {
        notifyError('There was a problem adding this insurance.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding this insurance.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const insuranceStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
