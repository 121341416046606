<template>
  <div>
    <button
      :hidden="isDisabled"
      class="button is-info is-light is-small is-outline is-rounded"
      @click="loadUpdateInsurance(insurance)"
    >
      Edit</button
    ><span class="pr-1">&nbsp;</span>
    <button
      :hidden="isDisabled"
      class="button is-info is-light is-small is-outline is-rounded"
      @click="confirmOwnerInsuranceDelete(name, ownerInsuranceID)"
    >
      Delete
    </button>
  </div>
</template>

<script>
export default {
  props: {
    loadUpdateInsurance: Function,
    confirmOwnerInsuranceDelete: Function,
    name: String,
    insurance: Object,
    ownerInsuranceID: [Number, String],
    isDisabled: Boolean
  }
}
</script>
