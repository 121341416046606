<template>
  <div insurance-modal>
    <div class="is-size-4" v-if="formData">{{ buttonText }} Owner Insurance</div>
    <b-steps v-model="activeStep" :has-navigation="false" label-position="right" size="is-small">
      <b-step-item label="Add Insurance" icon="account-key" add-insurance-step>
        <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
          <transition name="fade" mode="out-in" appear>
            <form @submit.prevent="handleSubmit(submitInsurance)">
              <fieldset>
                <div class="form-grid">
                  <div class="column is-12">
                    <div class="column is-12">
                      <valid-select
                        label="Coverage Type"
                        placeholder="Coverage Type"
                        vid="ownerInsuranceCoverageTypeID"
                        v-model="formData.ownerInsuranceCoverageTypeID"
                        aria-required="The coverage type is required"
                        rules="required"
                      >
                        <option
                          v-for="option in ownerInsuranceCoverageTypeList"
                          :value="option.value"
                          :key="option.value"
                          >{{ option.label }}</option
                        >
                      </valid-select>
                    </div>
                    <div class="column is-12">
                      <valid-input
                        v-model="formData.coverageAmount"
                        label="Coverage Amount"
                        name="coverageAmount"
                        type="decimal"
                        vid="coverageAmount"
                        placeholder="Coverage Amount"
                        rules="max:10"
                      />
                    </div>
                    <div class="column is-12">
                      <valid-input
                        v-model="formData.insuranceCompanyName"
                        label="Insurance Company Name"
                        name="insuranceCompanyName"
                        type="text"
                        vid="insuranceCompanyName"
                        placeholder="Insurance Company Name"
                        rules="required|max:100"
                      />
                    </div>
                    <div class="column is-12">
                      <valid-input
                        v-model="formData.policyNumber"
                        label="Policy Number"
                        name="policyNumber"
                        type="text"
                        vid="policyNumber"
                        placeholder="Policy Number"
                        rules="required|max:30"
                      />
                    </div>
                    <div class="column is-12">
                      <DatePicker
                        v-model="formData.expirationDate"
                        ariaLabel="Expiration Date"
                        label="Expiration Date"
                        ref="expirationDateComponent"
                      />
                    </div>
                    <div class="pt-5">
                      <button
                        v-if="typeof newId === 'number' && newId >= 1 && buttonText == 'Add'"
                        type="button"
                        class="button is-primary is-rounded"
                        :style="{ float: 'right', marginRight: '18px' }"
                        @click="activeStep = 1"
                      >
                        Next
                      </button>
                      <button
                        v-if="!newId || buttonText == 'Next'"
                        type="submit"
                        :disabled="loading"
                        class="button is-primary is-rounded float-right"
                      >
                        {{ buttonText }}
                      </button>
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                  <div class="field">
                    <span>{{ errors[0] }}</span>
                  </div>
                </ValidationProvider>
              </fieldset>
            </form>
          </transition>
        </ValidationObserver>
      </b-step-item>
      <b-step-item
        :clickable="newId ? true : false"
        ref="uploadpdf"
        label="Upload Document"
        icon="account-key"
      >
        <div ref="tab2" class="box is-12" v-if="newId">
          <section
            id="request-doc-upload"
            class="h-full overflow-auto mt-4 sm:p-8 w-full h-full flex flex-col"
          >
            <UploadModal :ownerInsuranceID="newId"> </UploadModal>
          </section>
          <b-button @click="leaveForm()" :style="{ float: 'right' }" type="is-primary"
            >Finish</b-button
          >
        </div>
      </b-step-item>
    </b-steps>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'
import ValidSelect from '@/components/inputs/ValidSelect'
import DatePicker from '@/components/inputs/DatePicker'
import UploadModal from './../uploadModal/UploadModal'

export default {
  props: {
    insurance: Object,
    ownerID: Number
  },

  components: {
    ValidInput,
    ValidSelect,
    ValidationObserver,
    ValidationProvider,
    DatePicker,
    UploadModal
  },

  mounted() {
    if (this.insurance && this.insurance != undefined) {
      this.newId = this.insurance.ownerInsuranceID

      console.debug('mounted this.newId=' + JSON.stringify(this.insurance))
    }
    console.debug('ownerID===' + this.ownerID)

    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ownerFilterSuggestions() {
      if (this.ownerFilterQuery && this.ownerDropDownList && this.ownerDropDownList != undefined) {
        return this.ownerDropDownList.filter(
          a => a.label.indexOf(this.ownerFilterQuery.toLowerCase()) !== -1
        )
      } else {
        return this.ownerDropDownList
      }
    }
  },

  data,
  methods
}
</script>
