<template>
  <PageContentLayoutOnly :aria-label="$t('insurance.title')" role="region">
    <ModernTable
      :region="region"
      :filters="filters"
      :rows="rows"
      :columns="columns"
      :pagination="{
        perPage: 12
      }"
      :loading="loading"
      headerColor="#FFF"
      condensed
    >
      <template v-slot:header>
        <div
          :style="{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '10px'
          }"
        >
          <div>
            <button
              @click.prevent="addInsurance(selectedInsurance)"
              class="button is-primary is-rounded"
              :style="{ float: 'left' }"
            >
              Add Insurance
            </button>
          </div>
          <div class="level-right">
            <b-radio v-model="radio" name="gridFilter" native-value="active">
              Active
            </b-radio>
            <b-radio v-model="radio" name="gridFilter" native-value="archived">
              Archived
            </b-radio>
          </div>
        </div>

        <div :style="{ width: '100%', height: '60px' }">
          <b-button
            type="is-ghost"
            @click="filters.show = !filters.show"
            :style="{ float: 'right', background: 'none', border: 0 }"
            :class="'filter-button ' + (filters.show ? 'active' : '')"
          >
            <b-icon pack="mdi" icon="filter-outline" size="is-small" style="display: inline-block">
            </b-icon>
            <div style="display: inline-block">Filter</div>
          </b-button>
        </div>
      </template>
    </ModernTable>
    <Modal :toggle.sync="toggle">
      <InsuranceModal :insurance="selectedInsurance" :ownerID="ownerID" />
    </Modal>
  </PageContentLayoutOnly>
</template>

<script>
import _get from 'lodash/get'
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import ModernTable from '@/components/tables/Modern/Table'
import { parseDate } from '@/utilities/Date/parse'
import { insuranceList } from '@/services/Units/Tabs/Insurance/store'
import OwnerInsuranceActions from './components/OwnerInsuranceActions'
import { notifyMessage, notifyWarning, notifyProblem } from '@/services/notify'
import kms from '@/services/kms'
import Download from './components/Download'
import { base64toBlob, showPdf } from '@/services/blobutility'

import InsuranceModal from './components/InsuranceModal'
import Modal from '@/components/Modal'

export default {
  name: 'Insurance',
  components: {
    PageContentLayoutOnly,
    ModernTable,
    Modal,
    InsuranceModal
  },

  data: () => ({
    isDebug: true,
    loading: true,
    selectedInsurance: null,
    toggle: false,
    unitID: 0,
    radio: 'active',

    styles: {
      dateSelector: {
        display: 'flex',
        padding: '12px',
        textAlign: 'center',
        verticalAlign: 'middle',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'flex-end'
      },
      filterButton: {
        float: 'right',
        marginTop: '4px'
      }
    },

    region: {},
    filters: {
      show: false
    },
    rows: [],
    columns: [
      {
        field: 'owner',
        label: 'Owner',
        aria: 'Type',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'coverageType',
        label: 'Coverage Type',
        aria: 'Coverage Type',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'insuranceCompany',
        label: 'Insurance Co.',
        aria: 'Insurance Co.',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'policy',
        label: 'Policy',
        aria: 'Policy',
        width: '15%',
        sortable: true,
        searchable: true
      },
      {
        field: 'coverageAmount',
        label: 'Amount',
        aria: 'Coverge Amount',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'expirationDate',
        date: true,
        label: 'Expire Date',
        aria: 'Expire Date',
        width: '10%',
        sortable: true,
        searchable: true
      },
      {
        field: 'certificate',
        label: '',
        width: '15%'
      }
    ]
  }),

  created() {
    if (this.isDebug == true)
      console.debug(
        JSON.stringify(this.$route.params) + 'authUser=' + JSON.stringify(this.authUser)
      )

    if (this.authUser && this.authUser != undefined) {
      this.ownerID = this.authUser.ownerID
    }
  },

  methods: {
    determineRows: function(list) {
      if (!Array.isArray(list)) {
        return
      }

      if (this.isDebug == true) console.debug('determineRows list=' + JSON.stringify(list))

      const dateFormat = 'LL'
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      })

      var rows = list.map(entry => {
        const ownerName = _get(entry, 'owner.name', '')
        const expirationCreated = parseDate(_get(entry, 'expirationDate', null), dateFormat)
        const coverageType = _get(entry, 'ownerInsuranceCoverageType.name', '')
        const coverageAmount = formatter.format(_get(entry, 'coverageAmount', 0))
        const insuranceCompany = _get(entry, 'insuranceCompanyName', '')
        const policy = _get(entry, 'policyNumber', '')
        const name = `${policy} ${coverageType}`
        const insuranceId = _get(entry, 'ownerInsuranceID', null)
        const isArchived = _get(entry, 'isArchived', false)

        return {
          owner: ownerName,
          coverageType: coverageType,
          insuranceCompany: insuranceCompany,
          policy: policy,
          coverageAmount: coverageAmount,
          expirationDate: expirationCreated,
          actions: {
            component: OwnerInsuranceActions,
            props: {
              name: name,
              insurance: entry,
              ownerInsuranceID: insuranceId,
              isDisabled: isArchived,
              loadUpdateInsurance: _insurance => {
                this.selectedInsurance = _insurance
                this.toggle = true
              },

              confirmOwnerInsuranceDelete: (_name, _ownerInsuranceID) => {
                this.$buefy.dialog.confirm({
                  title: 'Deleting Owner Insurance',
                  message: `Are you sure you want to <b>delete</b> this Owner Insurance, '${_name}'?`,
                  confirmText: 'Delete Owner Insurance',
                  type: 'is-danger',
                  hasIcon: true,
                  onConfirm: () => this.deleteOwnerInsurance(_ownerInsuranceID)
                })
              }
            }
          },
          certificate: {
            component: Download,
            props: {
              documentID: _get(entry, ['certificateDocumentID'], 0),
              ownerInsuranceID: _get(entry, ['ownerInsuranceID'], 0),
              downloadPdf: ownerInsuranceID => {
                this.downloadPdf(ownerInsuranceID)
              }
            }
          }
        }
      })

      this.rows = rows
    },

    addInsurance() {
      ;(this.selectedInsurance = null), (this.toggle = true)
    },

    async getOwnerId() {
      const unitID = this.$route.params.id

      console.debug('getOwnerId unitID=' + unitID)

      await insuranceList
        .dispatch('getUnitById', {
          unitID: unitID
        })
        .then(({ result }) => {
          if (result) {
            this.ownerID = result.primaryOwnerID
          }
        })
    },

    async loadInsurance() {
      if (this.isDebug == true) console.debug('in loadInsurance()...' + this.ownerID)
      await insuranceList
        .dispatch('getInsuranceList', {
          hoaID: this.hoaId,
          ownerID: this.ownerID
        })
        .then(({ list }) => {
          let filteredList = [{}]

          if (this.isDebug == true) console.debug('this.radio=' + this.radio)
          if (this.radio && this.radio == 'archived') {
            filteredList = list.filter(f => f.isArchived == true)
          } else {
            filteredList = list.filter(f => f.isArchived == false)
          }

          this.determineRows(filteredList)
        })
    },

    async deleteOwnerInsurance(id) {
      if (this.isDebug == true) console.debug('deleteOwnerInsurance id=' + id)

      await insuranceList.dispatch('deleteInsurance', {
        ownerInsuranceID: id,
        done: () => {
          this.loading = false
          this.reload()
        }
      })
    },

    async reload() {
      this.loading = true
      if (this.unit && this.unit != undefined) {
        this.unitID = this.unit.unitID
      }

      await this.loadInsurance()
      this.loading = false
    },

    async downloadPdf(ownerInsuranceID) {
      if (this.isDebug == true) console.debug('downloadPdf...')

      if (!ownerInsuranceID || ownerInsuranceID == undefined) {
        notifyProblem('The ownerInsuranceID is invalid for the download.')
      }

      try {
        const params = {
          ownerInsuranceID: ownerInsuranceID,
          asBase64: true,
          asRawBase64: true
        }

        const path = `/OwnerInsurance/OwnerInsurance/GetFile`
        const returned = await kms.get(path, {
          params
        })

        if (this.isDebug == true)
          console.debug(
            'path base64 path=' +
              path +
              ' - params' +
              JSON.stringify(params) +
              ' - returned=' +
              JSON.stringify(returned)
          )
        if (returned && returned != undefined) {
          if (this.isDebug == true) console.debug('base64pdf........................' + returned)
          const contentType = 'application/pdf'
          const blob = base64toBlob(returned, contentType)
          showPdf(blob)
          notifyMessage(`Successfully downloaded the certificate.`)
          return
        }
      } catch (e) {
        if (this.isDebug == true) console.debug(e)
        notifyWarning('There was a problem downloading the certificate.')
      }
    }
  },

  computed: {
    ...mapState({
      unit: state => state.hoa.unit,
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser', 'retrieveUser'])
    })
  },

  watch: {
    unit: 'getWorkOrders',
    radio: 'reload',
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  async mounted() {
    await this.getOwnerId()

    await this.reload()
  },

  i18n: {
    messages: {
      en: { insurance: { title: 'Owner Insurance' } }
    }
  }
}
</script>

<style></style>
