<template>
  <div>
    <span
      style="margin-left: -20px;"
      v-if="
        (typeof ownerInsuranceID === 'string' || typeof ownerInsuranceID === 'number') &&
          documentID > 0
      "
    >
      <button
        class="button is-info is-light is-small is-outline is-rounded"
        @click.prevent="downloadPdf(ownerInsuranceID)"
      >
        Pdf
      </button>
    </span>
    <span v-else>
      &nbsp;
    </span>
  </div>
</template>

<script>
export default {
  props: {
    ownerInsuranceID: [Number, String],
    documentID: [Number, String],
    downloadPdf: Function
  }
}
</script>
