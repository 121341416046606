/*
  import { uploadStore } from '@/services/Units/Tabs/Insurance/UploadModal/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async uploadDocument({}, { payload = {} }) {
    console.debug('in uploadDocument...' + JSON.stringify(payload))
    const formData = new FormData()
    formData.append('file', payload.file)

    // create container if not exists
    try {
      const path = `/OwnerInsurance/OwnerInsurance/StoreFile?ownerInsuranceID=${payload.ownerInsuranceID}`
      const uploadedFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug('uploadDocument with payload=' + JSON.stringify(uploadedFile))

      return {
        upload: uploadedFile
      }
    } catch (exception) {
      notifyProblem(`There was a problem uploading the file.`)
      console.error(exception)
    }

    return {
      uploaded: null
    }
  },

  async loadDocument({}, { payload = {} }) {
    try {
      const insurance = await kms.get(`/OwnerInsurance/OwnerInsurance/${payload.ownerInsuranceID}`)
      if (insurance) {
        if (!insurance.certificateDocumentID) {
          return {
            baseSixtyFour: null
          }
        }
      }

      const path = `/OwnerInsurance/OwnerInsurance/GetFile`
      const returned = await kms.get(path, {
        params: {
          ownerInsuranceID: payload.ownerInsuranceID,
          asBase64: payload.isBaseSixtyFour
        }
      })

      if (isDebug == true) console.debug('returned=' + JSON.stringify(returned))

      return {
        baseSixtyFour: returned
      }
    } catch (exception) {
      //notifyProblem(`There was a problem loading the file.`)
      console.error(exception)
    }

    return {
      baseSixtyFour: null
    }
  },

  // remove file id via an update
  async removeFile({ dispatch }, { payload = {}, done }) {
    try {
      const insurance = await kms.get(`/OwnerInsurance/OwnerInsurance/${payload.ownerInsuranceID}`)
      if (insurance) {
        const updatePayload = {
          ownerInsuranceID: insurance.ownerInsuranceID,
          ownerID: insurance.ownerID,
          ownerInsuranceCoverageTypeID: insurance.ownerInsuranceCoverageTypeID,
          certificateDocumentID: null,
          coverageAmount: insurance.coverageAmount,
          insuranceCompanyName: insurance.insuranceCompanyName,
          policyNumber: insurance.policyNumber,
          isArchived: insurance.isArchived,
          expirationDate: insurance.expirationDate
        }

        const results = await kms.put(`/OwnerInsurance/OwnerInsurance`, updatePayload)

        if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

        if (dispatch && dispatch != undefined) console.debug(dispatch)

        if (results.ownerInsuranceID > 0) {
          notifyMessage(`Successfully removed the attached document.`)
          done()
        } else {
          notifyError('There was a problem removing the attached document.')
        }
      }
    } catch (exception) {
      notifyProblem(`There was a problem removing the attached document.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const uploadStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
