import { insuranceStore } from '@/services/Units/Tabs/Insurance/InsuranceModal/store'
import $ from 'jquery'
import { notifyError } from '@/services/notify'
import _get from 'lodash/get'
import { isNumeric } from '@/utilities/validate/isNumeric'
import moment from 'moment'

export const methods = {
  async reload() {
    this.loading = true

    this.getOwnerInsuranceCoverageTypeDropDown()

    if (this.insurance && this.insurance != undefined) {
      //Update
      this.buttonText = 'Next'

      this.formData.ownerID = this.insurance.ownerID
      this.formData.ownerInsuranceID = this.insurance.ownerInsuranceID
      this.formData.ownerInsuranceCoverageTypeID = this.insurance.ownerInsuranceCoverageTypeID
      this.formData.coverageAmount = this.insurance.coverageAmount
      this.formData.insuranceCompanyName = this.insurance.insuranceCompanyName
      this.formData.policyNumber = this.insurance.policyNumber
      this.formData.expirationDate = null
      this.formData.expirationDate = this.insurance.expirationDate

      var expirationDate = this.insurance.expirationDate
      if (expirationDate) {
        this.$refs.expirationDateComponent.selected = new Date(this.insurance.expirationDate)
      }

      this.loading = false
    } else {
      this.buttonText = 'Add'
    }

    this.loading = false
  },

  async getOwnerInsuranceCoverageTypeDropDown() {
    await insuranceStore.dispatch('getOwnerInsuranceCoverageTypeList').then(({ list }) => {
      if (list) {
        this.ownerInsuranceCoverageTypeList = list
        if (this.isDebug == true)
          console.debug(
            'this.ownerInsuranceCoverageTypeList=' +
              JSON.stringify(this.ownerInsuranceCoverageTypeList)
          )
      }
    })
  },

  open() {
    this.formData = {
      ownerID: 0,
      ownerInsuranceID: 0,
      ownerInsuranceCoverageTypeID: 0,
      coverageAmount: 0,
      insuranceCompanyName: '',
      policyNumber: '',
      expirationDate: null
    }
    this.isOpen = true
    this.loading = false
  },

  async submitInsurance() {
    if (this.formData && !this.formData.expirationDate) {
      notifyError('Please select an expiration date. The expiration date is a required field.')
      return
    }

    if (this.formData && this.formData != undefined) {
      if (this.formData.ownerInsuranceID && this.formData.ownerInsuranceID > 0) {
        await this.updateInsurance()
      } else {
        await this.addInsurance()
      }
    }
  },

  async addInsurance() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem processing this insurance.')
      return
    }

    if (isNumeric({ n: this.formData.coverageAmount }) == false) {
      notifyError('Please enter only numbers for the coverage amount.')
      return
    }

    if (this.formData.coverageAmount) this.loading = true

    if (this.isDebug == true) console.debug('addInsurance params=' + JSON.stringify(this.formData))

    await insuranceStore.dispatch('addInsurance', {
      ownerID: this.ownerID,
      ownerInsuranceCoverageTypeID: _get(this.formData, 'ownerInsuranceCoverageTypeID', 0),
      certificateDocumentID: null,
      coverageAmount: _get(this.formData, 'coverageAmount', 0),
      insuranceCompanyName: _get(this.formData, 'insuranceCompanyName', ''),
      policyNumber: _get(this.formData, 'policyNumber', ''),
      expirationDate: this.formData.expirationDate
        ? moment(this.formData.expirationDate).format('YYYY-MM-DD')
        : null,
      done: ({ details }) => {
        if (details && details != undefined) {
          this.newId = details.ownerInsuranceID
          if (this.isDebug == true)
            console.debug('details newId=' + JSON.stringify(details) + ', newId=' + this.newId)
          this.activeStep = 1
        }
        this.loading = false
      }
    })
  },

  async updateInsurance() {
    if (
      !this.formData ||
      this.formData == undefined ||
      !this.formData.ownerInsuranceID ||
      this.formData.ownerInsuranceID == undefined
    ) {
      notifyError('There was a problem updating the selected insurance.')
      return
    }

    if (isNumeric({ n: this.formData.coverageAmount }) == false) {
      notifyError('Please enter only numbers for the coverage amount.')
      return
    }

    this.loading = true

    const payload = {
      ownerInsuranceID: this.insurance.ownerInsuranceID,
      ownerID: this.ownerID,
      ownerInsuranceCoverageTypeID: _get(this.formData, 'ownerInsuranceCoverageTypeID', 0),
      certificateDocumentID: null,
      coverageAmount: _get(this.formData, 'coverageAmount', 0),
      insuranceCompanyName: _get(this.formData, 'insuranceCompanyName', ''),
      policyNumber: _get(this.formData, 'policyNumber', ''),
      expirationDate: this.formData.expirationDate
        ? moment(this.formData.expirationDate).format('YYYY-MM-DD')
        : null
    }

    await insuranceStore.dispatch('updateInsurance', {
      payload: payload,
      done: ({ details }) => {
        //if the expiration date changed, then update the id.
        if (details) {
          this.newId = details.ownerInsuranceID
          this.insurance.ownerInsuranceID = details.ownerInsuranceID
          this.activeStep = 1
        }
        this.loading = false
      }
    })

    this.loading = false
  },

  leaveForm() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
    this.loading = false
  }
}
