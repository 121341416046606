var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageContentLayoutOnly',{attrs:{"aria-label":_vm.$t('insurance.title'),"role":"region"}},[_c('ModernTable',{attrs:{"region":_vm.region,"filters":_vm.filters,"rows":_vm.rows,"columns":_vm.columns,"pagination":{
      perPage: 12
    },"loading":_vm.loading,"headerColor":"#FFF","condensed":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{style:({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '10px'
        })},[_c('div',[_c('button',{staticClass:"button is-primary is-rounded",style:({ float: 'left' }),on:{"click":function($event){$event.preventDefault();return _vm.addInsurance(_vm.selectedInsurance)}}},[_vm._v(" Add Insurance ")])]),_c('div',{staticClass:"level-right"},[_c('b-radio',{attrs:{"name":"gridFilter","native-value":"active"},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(" Active ")]),_c('b-radio',{attrs:{"name":"gridFilter","native-value":"archived"},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},[_vm._v(" Archived ")])],1)]),_c('div',{style:({ width: '100%', height: '60px' })},[_c('b-button',{class:'filter-button ' + (_vm.filters.show ? 'active' : ''),style:({ float: 'right', background: 'none', border: 0 }),attrs:{"type":"is-ghost"},on:{"click":function($event){_vm.filters.show = !_vm.filters.show}}},[_c('b-icon',{staticStyle:{"display":"inline-block"},attrs:{"pack":"mdi","icon":"filter-outline","size":"is-small"}}),_c('div',{staticStyle:{"display":"inline-block"}},[_vm._v("Filter")])],1)],1)]},proxy:true}])}),_c('Modal',{attrs:{"toggle":_vm.toggle},on:{"update:toggle":function($event){_vm.toggle=$event}}},[_c('InsuranceModal',{attrs:{"insurance":_vm.selectedInsurance,"ownerID":_vm.ownerID}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }